import RegistPage from "./components/registration/I324/registration";
import PreregistPage from "./components/registration/I324/preregistration";
import PostPage from "./components/registration/I324/postregister";

import RegistPage_dc from "./components/registration/K124/registration";
import PreregistPage_dc from "./components/registration/K124/preregistration";
import PostPage_dc from "./components/registration/K124/postregister";

const AppRegistRoutes = [
  {
    path: "i324/preregistration",
    element: <PreregistPage />,
  },
  {
    path: "i324/preregistration/:cp",
    element: <PreregistPage />,
  },
  {
    path: "i324/registration",
    element: <RegistPage />,
  },
  {
    path: "i324/postregister/:res/:key",
    element: <PostPage />,
  },

  {
    path: "k124/preregistration",
    element: <PreregistPage_dc />,
  },
  {
    path: "k124/preregistration/:cp",
    element: <PreregistPage_dc />,
  },
  {
    path: "k124/registration",
    element: <RegistPage_dc />,
  },
  {
    path: "k124/postregister/:res/:key",
    element: <PostPage_dc />,
  },
];

export default AppRegistRoutes;
