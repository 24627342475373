import K124 from "./components/landingpage/K124/landingpage";

import I324 from "./components/landingpage/I324/landingpage";

const AppLandingRoutes = [
  {
    path: "/i324",
    element: <I324 />,
  },
  {
    path: "/i324/:cp",
    element: <I324 />,
  },

  {
    path: "/k124",
    element: <K124 />,
  },
  {
    path: "/k124/:cp",
    element: <K124 />,
  },
];

export default AppLandingRoutes;
